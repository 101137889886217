/*===================
Blog Styles 
=====================*/

.blog-style--1 {
    position: relative;
    margin-bottom: 40px;
    @extend %transition;
    overflow: hidden;
    border-radius: 6px;

    .thumbnail {
        a {
            display: block;
            position: relative;
            z-index: 1;

            img {
                @extend %transition;
            }

            &::after {
                background: linear-gradient(to bottom, $theme-color 0, #000000 100%);
                position: absolute;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                content: "";
                z-index: 1;
                opacity: 0;
                @extend %transition;

                @media #{$large-mobile} {
                    opacity: 0.85;
                    top: 0;
                }

            }
        }
    }

    .content {
        position: absolute;
        bottom: -92px;
        width: 100%;
        padding: 50px 35px;
        z-index: 3;
        @extend %transition;

        @media #{$large-mobile} {
            bottom: 0;
        }

        @media #{$large-mobile} {
            padding: 30px 20px;
        }

        &::before {
            position: absolute;
            content: "";
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0, #000000 70%);
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            opacity: 1;
            @extend %transition;
            z-index: -1;

            @media #{$large-mobile} {
                opacity: 0;
            }
        }

        p {
            &.blogtype {
                font-size: 14px;
                margin-bottom: 5px;
                color: #c6c9d8;
            }
        }

        h4 {
            &.title {
                margin-bottom: 0;

                a {
                    color: #ffffff;
                    font-size: 24px;
                    font-weight: 500;
                    line-height: 36px;
                    @extend %transition;
                }
            }
        }

        .blog-btn {
            @extend %transition;
            margin-top: 35px;
            transition: 0.7s;
            opacity: 0;
            visibility: hidden;

            @media #{$large-mobile} {
                margin-top: 22px;
            }

            @media #{$large-mobile} {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &:hover {
        .content {
            bottom: 0;

            &::before {
                opacity: 0;
            }
        }

        .thumbnail {
            a {
                img {
                    transform: scale(1.1);
                }

                &::before {
                    opacity: 0;
                }

                &::after {
                    opacity: 0.85;
                    top: 0;
                }
            }
        }

        .content {
            .blog-btn {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}


// Horizontal blog posts

.band {
    width: 90%;
    max-width: 1240px;
    margin: 0 auto;
    padding-bottom: 20px;
    display: grid;

    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 20px;

    @media (min-width: 30em) {
        grid-template-columns: 1fr 1fr;
    }

    @media (min-width: 60em) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.card {
    background: white;
    text-decoration: none;
    color: #444;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    min-height: 100%;

    // sets up hover state
    position: relative;
    top: 0;
    transition: all .1s ease-in;

    &:hover {
        top: -2px;
        box-shadow: 0 4px 5px rgba(0, 0, 0, 0.2);
    }

    article {
        padding: 20px;
        flex: 1;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    h1 {
        font-size: 20px;
        margin: 0;
        color: #333;
    }

    p {
        flex: 1;
        line-height: 1.4;
    }

    span {
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        letter-spacing: .05em;
        margin: 2em 0 0 0;
        padding: 2px;
        border-radius: 3px;
    }

    .thumb {
        padding-bottom: 60%;
        background-size: cover;
        background-position: center center;
    }
}

.item-1 {
    @media (min-width: 60em) {
        grid-column: 1 / span 2;

        h1 {
            font-size: 24px;
        }
    }
}

@keyframes flickerAnimation { /* flame pulses */
    0%   { opacity:1; }
    50%  { opacity:0; }
    100% { opacity:1; }
  }

.fade-in-left-right{
    opacity:1;  
    animation: flickerAnimation 2s infinite;
}