@import "../../../public/assets/css/plugins.css";
@import "../../../public/assets/scss/style";

.content-project-card {
  position: absolute;
  bottom: 0px;
  z-index: 10;
  padding: 0;
  width: 100%;
  border-radius: 0px 0px 7px 7px;
  text-align: center;
  background-color: $theme-color;

  a {
    color: #fff;
  }

  //   &:hover {
  //     transform:  scale(1.2);
  //   }
}
