//===== Colors =====//
// $theme-color: #f9004d;
// $theme-color-2: #f61b10;
// $theme-color-3: #ef0963;
$theme-color: #967eff;
$theme-color-2: #7a78e2;
$theme-color-3: #766adf;
$body-color: #1d1d24;
$heading-color: #1f1f25;
$border-color: #d4d4d4;
$white: #fff;


/*===============================
    Font Family 
=================================*/
$body-font: 'Poppins', sans-serif;
$heading-font: 'Poppins', sans-serif;


// Heading Font List
$font-1: 'Poppins', sans-serif;

$fontList: $font-1;


/*===============================
    Color Variation 
=================================*/
$color-1: $theme-color;
// $color-2:  #f4769a;
$color-2: #8d76f4;
$color-3: #2f21b3;
$color-4: #8956e2;
$colorList: $color-1, $color-2 , $color-3 , $color-4;






//===== Line Height =====//
$body-line-height: 1.714286;
//==== Font size =====//
$body-font-size: 14px;
$h1: 64px;
$h2: 54px;
$h3: 24px;
$h4: 20px;
$h5: 18px;
$h6: 16px;

//===== Social Colors =====//
$facebook : #3B5998;
$twitter : #00ACEE;
$google-plus : #DD4B39;
$pinterest : #C8232C;
$linkedin : #0E76A8;
$flickr : #FF0084;
$google : #dd4b39;
$youtube : #cd201f;




// Layouts
$smlg-device: 'only screen and (max-width: 1199px)';

$extra-device: 'only screen and (min-width: 1600px) and (max-width: 1919px)';

$laptop-device: 'only screen and (min-width: 1200px) and (max-width: 1599px)';
$lg-layout: 'only screen and (min-width: 992px) and (max-width: 1199px)';
$md-layout: 'only screen and (min-width: 768px) and (max-width: 991px)';
$sm-layout: 'only screen and (max-width: 767px)';
$large-mobile: 'only screen and (max-width: 575px)';
/* container 450px*/
$small-mobile: 'only screen and (max-width: 479px)';
/* container 300px*/

// @media #{$large-mobile} {
//     .container {
//         width: 450px;
//     }
// }

// @media #{$small-mobile} {
//     .container {
//         // width: 320px;
//         width: 380px;
//     }
// }